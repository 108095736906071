<template>
  <div>
    <b-row class="content-header mb-2">
      <!-- Content Left -->
      <b-col class="content-header-left mt-2" cols="12" md="12">
        <breadcrumb />
      </b-col>
      <b-col cols="12" class="mt-2">
        <h5 class="mb-0">تقویم روزهای پیک</h5>
        <small class="text-muted">
          می توانید روزهای پیک رو مشاهده و ویرایش کنید.
        </small>
      </b-col>
      <b-overlay class="w-100" :show="getPeakDatesLoading" rounded="sm">
        <b-row class="p-1">
      <b-col class="mt-2" md="6" cols="6">
        <date-picker
          auto-submit
          inline
          multiple
          clearable
          format="YYYY-MM-DD"
          display-format="jYYYY-jMM-jDD"
          v-model="dates"
          custom-input="#my-custom-editable-input"
        />
      </b-col>
      <b-col class="mt-2" md="6">
        <textarea
          style="width: 100%"
          id="my-custom-editable-input"
          type="text"
          rows="7"
          placeholder="YYYY/MM/DD"
        />

        {{ dates }}
        <br />
        <b-button class="mt-2" variant="primary" @click="submitForm()">
          <div v-if="submitLoading" class="px-1">
            <b-spinner small style="width: 1.3rem; height: 1.3rem" />
            <span class="sr-only">Loading...</span>
          </div>
          <span v-else class="text-capitalize"> ذخیره </span>
        </b-button>
      </b-col>
      </b-row>
      </b-overlay>
    </b-row>
  </div>
</template>

<script>
import {
  BRow,
  BCol,
  BFormGroup,
  BFormInput,
  BFormFile,
  BCard,
  BAvatar,
  BButton,
  BSpinner,
  BSidebar,
  BContainer,
  BFormTextarea,
  BFormInvalidFeedback,
  BFormRadio,
  BFormCheckbox,
  BFormCheckboxGroup,
  BOverlay
} from "bootstrap-vue";
import PerPage from "@/components/PerPage.vue";
import Breadcrumb from "@/components/Breadcrumb.vue";
import Pagination from "@/components/Pagination.vue";
import Search from "@/components/Search.vue";
import TableHead from "@/components/TableHead.vue";
import vSelect from "vue-select";
import FilePicker from "@/components/FilePicker.vue";
import ErrorMessage from "@/components/ErrorMessage.vue";
import VuePersianDatetimePicker from "vue-persian-datetime-picker";

export default {
  components: {
    BRow,
    BCol,
    PerPage,
    BFormGroup,
    BFormInvalidFeedback,
    BFormInput,
    BFormFile,
    BCard,
    BAvatar,
    BButton,
    Breadcrumb,
    Pagination,
    Search,
    TableHead,
    BSpinner,
    BSidebar,
    BContainer,
    BFormTextarea,
    vSelect,
    BFormRadio,
    BFormCheckbox,
    BFormCheckboxGroup,
    FilePicker,
    ErrorMessage,
    datePicker: VuePersianDatetimePicker,
    BOverlay
  },
  data() {
    return {
      dates: [],
      submitLoading: false,
      getPeakDatesLoading:false,
      peakDates:[],
      errorMessage:null
    };
  },
  mounted() {
    this.$store.commit("verticalMenu/UPDATE_VERTICAL_MENU_COLLAPSED", false);
    this.getPeakCalender()
  },
  watch: {},
  methods: {
    async getPeakCalender() {
      this.getPeakDatesLoading=true
      if (typeof this.cancelToken != typeof undefined) {
        this.cancelToken.cancel("Operation canceled due to new request.");
      }
      //Save the cancel token for the current request
      this.cancelToken = this.$http.CancelToken.source();

      

      const requestData = {
        method: "get",
        url: "/admin/courier_calendars",
      };
      this.$http(requestData)
        .then((response) => {
          console.log("peakDates",response)
          let data = response.data.data[0].data;
          this.peakDates = data;
          this.dates=this.peakDates
          this.getPeakDatesLoading = false;
        })
        .catch((err) => {
          console.log(err);
          this.getPeakDatesLoading = false;
        });
    },
    submitForm() {
      if (this.submitLoading) return;
      let errorMessage = null;
      if (!errorMessage && this.dates.length==0) {
        errorMessage = "لطفا حداقل یک تاریخ را انتخاب کنید.";
      }
      if (errorMessage) {
        this.$bvToast.toast(errorMessage, {
          title: this.$t("Error"),
          variant: "danger",
          solid: true,
        });
        return;
      }

      this.submitLoading = true;

     

      const requestData = {
        method:  "post",
        url: `/admin/courier_calendars`,
        data: {data:this.dates}
      };

      this.$http(requestData)
        .then((response) => {
          let message = "تاریخ ها با موفقیت اضافه شد";
          if (this.editMode) message = "تاریخ ها با موفقیت ویرایش شد";
          this.$bvToast.toast(message, {
            title: this.$t("Done"),
            variant: "success",
            solid: true,
          });
          

          this.submitLoading = false;
        })
        .catch((err) => {
          let message = "خطا در افزودن تاریخ ها";
          if (this.editMode) message = "خطا در افزودن تاریخ ها";
          if (err.response && err.response.data && err.response.data.message)
            message = err.response.data.message;
if (
            err.response &&
            err.response.data &&
            err.response.data.response &&
            err.response.data.response.meta &&
            err.response.data.response.meta.errors &&
            err.response.data.response.meta.errors.data[0]
          )
            this.errorMessage = err.response.data.response.meta.errors.data[0];
          this.$bvToast.toast(message, {
            title: this.$t("Error"),
            variant: "danger",
          });
          this.$bvToast.toast(this.errorMessage, {
            title: this.$t("Error"),
            variant: "danger",
          });
          this.submitLoading = false;
          console.log(err);
            this.dates=this.peakDates
        });
    },
  },
  //__________________________________________________________________________________
};
</script>
<style lang="scss"></style>
